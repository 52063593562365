exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cennik-js": () => import("./../../../src/pages/cennik.js" /* webpackChunkName: "component---src-pages-cennik-js" */),
  "component---src-pages-galeria-js": () => import("./../../../src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-mycie-okien-na-wysokosciach-krakow-js": () => import("./../../../src/pages/mycie-okien-na-wysokosciach-krakow.js" /* webpackChunkName: "component---src-pages-mycie-okien-na-wysokosciach-krakow-js" */),
  "component---src-pages-mycie-okien-po-remontach-krakow-js": () => import("./../../../src/pages/mycie-okien-po-remontach-krakow.js" /* webpackChunkName: "component---src-pages-mycie-okien-po-remontach-krakow-js" */),
  "component---src-pages-mycie-okien-w-domach-krakow-js": () => import("./../../../src/pages/mycie-okien-w-domach-krakow.js" /* webpackChunkName: "component---src-pages-mycie-okien-w-domach-krakow-js" */),
  "component---src-pages-mycie-witryn-sklepowych-krakow-js": () => import("./../../../src/pages/mycie-witryn-sklepowych-krakow.js" /* webpackChunkName: "component---src-pages-mycie-witryn-sklepowych-krakow-js" */),
  "component---src-pages-mycie-zabudowy-balkonowej-krakow-js": () => import("./../../../src/pages/mycie-zabudowy-balkonowej-krakow.js" /* webpackChunkName: "component---src-pages-mycie-zabudowy-balkonowej-krakow-js" */),
  "component---src-pages-o-firmie-js": () => import("./../../../src/pages/o-firmie.js" /* webpackChunkName: "component---src-pages-o-firmie-js" */)
}

